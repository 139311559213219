.linkContainer {
  margin-left: 20px;
  list-style-type: none;
  height: 50px;
  display: flex;
  align-items: center;
}
.lastLinkContainer {
  position: absolute;
  right: 20px;
  margin-left: 0px;
}

.link {
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

@media (max-width: 900px) {
  .linkContainer {
    margin-left: 5px;
  }
  .lastLinkContainer {
    position: unset;
  }
}
