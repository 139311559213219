.container {
  width: 100%;
  max-height: 70vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.9em;
  margin-bottom: 50px;
}

.table {
  display: inline-block;
  border-spacing: 0;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  height: 90%;
  overflow-y: scroll;
}

.trLastTd {
  border-bottom: 0;
}

.cell {
  height: 40px;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  border-bottom: 1px solid #ddd;
  /*border-right: 1px solid #ddd;*/
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
}
.capitalize {
  text-transform: capitalize;
}

.resizer {
  display: inline-block;
  /* background: blue; */
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action:none;
}
.isResizing {
  background-color: #ddd;
}

.pagination {
  height: 10%;
  margin-top: 20px;
}

.pageSelect {
  display: flex;
  align-items: center;
}
